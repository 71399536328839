import React from "react"
import "../styles/blocks/not-available.scss"

const NotAvailable = () => {
  return (
    <div className="container">
      <div className="box">
        <p>Unfortunately, this website is not available at your location.</p>
      </div>
    </div>
  )
}

export default NotAvailable
